import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { Zoom } from '@mui/material';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Path, useLocation, useNavigate, useHref
} from 'react-router-dom';
import useAuth from '../../context/AuthProvider';
import { UserRole } from '../user/domain';
import { navStore } from './navStore';

import { Share } from '../home/Share';
import { useGetCurrentPlayerQuery, useGetDetailsQuery } from '../api/apiSlice';
import logo from '../../assets/images/logo.png';

interface ResponsiveAppBarProps {
  isMobileOnly?: boolean;
  donorPublicId?: string;
  donorId?: number;
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          background: 'white',
        },
      },
    },
  },
});

const isCallToAction = (type: string): boolean => type === 'donate';

const ResponsiveAppBar: React.FC<ResponsiveAppBarProps> = (
  {
    isMobileOnly = false, donorId = 0, donorPublicId = '',
  }: ResponsiveAppBarProps
) => {
  const mainHref = useHref('/');
  const { logout, user } = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorElShare, setAnchorElShare] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { data: detailsData } = useGetDetailsQuery();
  const { pathname } = useLocation();
  const { data: playerData } = useGetCurrentPlayerQuery();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenShareMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElShare(event.currentTarget);
  };

  const handleCloseNavMenu = (event: React.MouseEvent<HTMLElement> | null = null, to: Path | string = '', type = ''): void => {
    if (type === 'share' && event) {
      handleOpenShareMenu(event);
      return;
    }
    setAnchorElNav(null);
    if (type === 'donate') {
      let donateLink = '/';
      if (donorId && donorPublicId) {
        donateLink = `/Me/${donorId}/${donorPublicId}`;
      }
      navigate(donateLink, {
        state: {
          scrollTo: 'donate',
        },
      });
      // window.location.replace(`${window.location.protocol}//${window.location.host}${href}/#donate`);
    }
    if (to) {
      navigate(to);
    }
  };

  const handleCloseUserMenu = async (type?: string, to?: Path | string): Promise<void> => {
    if (type && type === 'logout') {
      await logout();
    } else if (to) {
      navigate(to);
    }
    setAnchorElUser(null);
  };

  const handleCloseShareMenu = (): void => {
    setAnchorElShare(null);
  };

  const mainPages = navStore.main.filter(
    (page): boolean => !page.roles
      || (!user?.roleName && page.roles.includes(UserRole.Guest))
      || (!!user?.roleName && page.roles.includes(user?.roleName))
  );

  const userPages = navStore.user.filter(
    (page): boolean => !page.roles
      || (!!user?.roleName && page.roles.includes(user?.roleName))
  );

  const isActive = (pageUrl: string | Path): boolean => (
    pageUrl.toString() !== '' && (pathname === pageUrl || pathname.startsWith(pageUrl.toString()))
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar
        position={isMobileOnly ? 'sticky' : 'static'}
        sx={{
          boxShadow: isMobileOnly ? undefined : 'none',
          display: { xs: 'flex' },
          pt: 1,
          pb: 1,
        }}
        color={isMobileOnly ? 'primary' : 'transparent'}
        enableColorOnDark
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
              <Button
                endIcon={anchorElNav ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="primary"
                size="large"
                sx={{ marginRight: 'auto' }}
              >
                Menu
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={() => handleCloseNavMenu()}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {mainPages.map((page) => (
                  (detailsData?.isSharingAvailable || page.type !== 'share') && (
                  <MenuItem key={page.type} onClick={(event) => handleCloseNavMenu(event, page.url, page.type)}>
                    <Typography textAlign="center" sx={{ pr: '1.5em' }}>
                      {page.label}
                    </Typography>
                  </MenuItem>
                  )))}
              </Menu>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Link
                href={mainHref}
                sx={{
                  display: { xs: 'none', md: 'inline' },
                  textAlign: 'center',
                }}
              >
                <img src={logo} alt="Fan-Pledge.com" width="100%" className="logo-img" />
              </Link>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }} justifyContent="flex-end">
              {mainPages.filter((page) => !mobileScreen || page.type === 'donate').map((page) => (
                (detailsData?.isSharingAvailable || page.type !== 'share') && (
                <Button
                  key={page.type}
                  color={isCallToAction(page.type) ? 'success' : 'primary'}
                  variant={isCallToAction(page.type) ? 'contained' : 'text'}
                  onClick={(event) => handleCloseNavMenu(event, page.url, page.type)}
                  sx={{
                    my: 1,
                    mx: 1,
                    display: 'block',
                    textTransform: 'initial',
                    color: isActive(page.url) ? '#a4d6ec' : '#ffffff',
                  }}
                >
                  {page.label}
                </Button>
                )
              ))}
            </Box>

            {!user?.id && detailsData?.isAccountsEnabled && isMobileOnly ? (
              <Zoom in>
                <Box sx={{ flexGrow: 0, display: { xs: 'flex' } }}>
                  {navStore.guest.map((page) => (
                    <Button
                      onClick={(event) => handleCloseNavMenu(event, page.url, page.type)}
                      variant={isMobileOnly ? 'contained' : 'text'}
                      color="success"
                      key={page.type}
                      sx={{ color: '#ffffff' }}
                    >
                      {page.label}
                    </Button>
                  ))}
                </Box>
              </Zoom>
            ) : null}

            {user?.id ? (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <Button
                    component="label"
                    onClick={handleOpenUserMenu}
                    variant="text"
                    tabIndex={-1}
                    size="small"
                    sx={{
                      color: '#ffffff',
                    }}
                    startIcon={!playerData || !playerData.photo ? (
                      <AccountCircle fontSize="large" sx={{ color: isMobileOnly ? 'black' : 'white' }} />
                    ) : (
                      <Avatar sx={{ width: 32, height: 32 }} src={playerData.photo} alt="O" />
                    )}
                  >
                    Account
                  </Button>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={() => handleCloseUserMenu()}
                >
                  {userPages.map((userPage) => (
                    <MenuItem key={userPage.type} onClick={() => handleCloseUserMenu(userPage.type, userPage.url)}>
                      <Typography textAlign="center">
                        {userPage.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ) : null}
          </Toolbar>
          <Share
            open={!!anchorElShare}
            onCloseItem={() => {
              handleCloseNavMenu();
              handleCloseShareMenu();
            }}
            onClose={() => {
              handleCloseNavMenu();
              handleCloseShareMenu();
            }}
            anchorEl={anchorElShare}
          />
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

ResponsiveAppBar.defaultProps = {
  isMobileOnly: false,
  donorId: 0,
  donorPublicId: '',
};

export default ResponsiveAppBar;
