import { lazy } from 'react';
import Loadable from '../components/Loadable';
import { RequireAuth } from '../components/RequireAuth';
import { GlobalLayout } from '../features/layout/GlobalLayout';
import { InnerLayout } from '../features/layout/InnerLayout';
import { UserRole } from '../features/user/domain';
import { getBasename } from '../utils';

const App = Loadable(lazy(() => import('../App')));
const Main = Loadable(lazy(() => import('../pages/Main')));
const Join = Loadable(lazy(() => import('../pages/Join/Join')));
const Login = Loadable(lazy(() => import('../pages/Login')));
const AccountContact = Loadable(lazy(() => import('../pages/Account/Contact')));
const Finale = Loadable(lazy(() => import('../pages/Account/Finale')));
const AccountDonation = Loadable(lazy(() => import('../pages/Account/Donation')));
const AccountPledge = Loadable(lazy(() => import('../pages/Account/Pledge')));
const AccountManage = Loadable(lazy(() => import('../pages/Account/Manage')));
const ForgotPassword = Loadable(lazy(() => import('../pages/Account/ForgotPassword')));
const AccountLockout = Loadable(lazy(() => import('../pages/Account/Lockout')));
const Live = Loadable(lazy(() => import('../pages/Fundraiser/Live')));

const CoachDonation = Loadable(lazy(() => import('../pages/Coach/Donation')));
const CoachTotal = Loadable(lazy(() => import('../pages/Coach/Total')));
const CoachPledge = Loadable(lazy(() => import('../pages/Coach/Pledge')));
const CoachContact = Loadable(lazy(() => import('../pages/Coach/Contact')));
const CoachCheck = Loadable(lazy(() => import('../pages/Coach/Check')));

const Subscribe = Loadable(lazy(() => import('../pages/Fundraiser/Subscribe')));
const Unsubscribe = Loadable(lazy(() => import('../pages/Fundraiser/Unsubscribe')));

/*
export default [
  {
    path: '/',
    element: getBasename() === '/' ? <App /> : <Main />,
  },
  {
    path: '/hello',
    element: <Hello />,
  },
]; */

export default [
  {
    path: '/',
    element: <GlobalLayout />,
    children: [
      {
        path: '/',
        element: getBasename() === '/' ? <App /> : <Main />,
      },
      {
        path: '/Account',
        element: <InnerLayout />,
        children: [
          {
            path: '/Account/Join',
            element: <Join />,
          },
          {
            path: '/Account/Login',
            element: <Login />,
          },
          {
            path: '/Account/Contact',
            element: <RequireAuth roles={[UserRole.Player]}><AccountContact /></RequireAuth>,
          },
          {
            path: '/Account/Finale',
            element: <RequireAuth roles={[UserRole.Player]}><Finale /></RequireAuth>,
          },
          {
            path: '/Account/Pledge',
            element: <RequireAuth roles={[UserRole.Player]}><AccountPledge /></RequireAuth>,
          },
          {
            path: '/Account/Donation',
            element: <RequireAuth roles={[UserRole.Player]}><AccountDonation /></RequireAuth>,
          },
          {
            path: '/Account/Manage',
            element: <RequireAuth><AccountManage /></RequireAuth>,
          },
          {
            path: '/Account/ForgotPassword',
            element: <ForgotPassword />,
          },
          {
            path: '/Account/Lockout',
            element: <AccountLockout />,
          },
        ],
      },
      {
        path: '/Coach',
        element: <InnerLayout />,
        children: [
          {
            path: '/Coach',
            element: <RequireAuth roles={[UserRole.Coach, UserRole.Admin]}><CoachDonation /></RequireAuth>,
          },
          {
            path: '/Coach/Totals',
            element: <RequireAuth roles={[UserRole.Coach, UserRole.Admin]}><CoachTotal /></RequireAuth>,
          },
          {
            path: '/Coach/Pledges',
            element: <RequireAuth roles={[UserRole.Coach, UserRole.Admin]}><CoachPledge /></RequireAuth>,
          },
          {
            path: '/Coach/Contacts',
            element: <RequireAuth roles={[UserRole.Coach, UserRole.Admin]}><CoachContact /></RequireAuth>,
          },
          {
            path: '/Coach/Checks',
            element: <RequireAuth roles={[UserRole.Coach, UserRole.Admin]}><CoachCheck /></RequireAuth>,
          },
        ],
      },
      {
        path: '/Account/Join',
        element: <Join />,
      },
      {
        path: '/Verify/:id/:publicId',
        element: <Main />,
      },
      {
        path: '/Verify/:id/:publicId/:token',
        element: <Main />,
      },
      {
        path: '/Fundraiser',
        element: <InnerLayout />,
        children: [{
          path: '/Fundraiser/Live',
          element: <RequireAuth roles={[UserRole.Coach, UserRole.Admin]}><Live /></RequireAuth>,
        }, {
          path: '/Fundraiser/Subscribe',
          element: <Subscribe />,
        }, {
          path: '/Fundraiser/Unsubscribe',
          element: <Unsubscribe />,
        }],
      },
      {
        path: '/Participant/:playerId',
        element: <Main />,
      },
    ],
  },
];
